@import url('https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@200;800&display=swap');
html, body {
  padding:0;
  margin:0;
  font-size:24px;
  font-family: 'Saira Extra Condensed', sans-serif;
  box-sizing: border-box;;
  zoom: 95%;
}
.is-bold {font-weight: bold;;}
.button.is-small {margin-right: 10px;}
.wait {
  z-index: 10000000000;;
  margin-top: 12px;
  left:40px;
  width:150px;
  height: 20px;
}.avatar {
  width:150px;
  background-color:white;
  float:left;
  display: block;
  height: 150px;;
  text-align: center;;
  padding-top:20px;
  font-size:80px ;
  color:#938383;
  margin-right: 15px;
}
p.closeWindow {
  text-align: right;

}
.is-pointer {
  cursor:pointer;
 
  
}
p.closeWindow i {
  color:#666;
  font-size:34px;
  cursor:pointer
}
p.closeWindow i:hover {
  opacity: .7;
}
.userId {
  width:100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border:1px solid #ddd;
  padding:5px;
}
.editField {
  border:2px solid #ddd;
  padding:15px;
  margin-top:15px;
}
.notification .button {
  min-width: 90%;
}
.wait .text {
  text-align: right;
  float:left;
  width:98px;

  font-weight: bold;
}
.wait .dots {

  width:50px;
  float:left;
  margin-top:20px
}
.wait .dot1,  .wait .dot2, .wait .dot3{
  width: 6px;
  height: 6px;
  margin-left: 3px;
  float: left;
  background-color: gray  ;
  border-radius: 100%;
  

}
.wait .dot1 {  animation:bounce .7s linear infinite;}
.wait .dot2 {  animation:bounce .8s linear infinite}
.wait .dot3 {  animation:bounce .9s linear infinite}
@-webkit-keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
  40% {-webkit-transform: translateY(-10px);} 
  60% {-webkit-transform: translateY(-8px);} 
} 

@keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-10px);} 
  60% {transform: translateY(-8px);} 
}

.bounce { 
  -webkit-animation-name: bounce; 
  animation-name: bounce; 
}
* {
  box-sizing: border-box;;
}
.title.is-1{font-size:3em;padding:0px 20px;margin:0}
/* SINGLE COLOR */
.grid {
  display: flex;
  width:100%; 
  border-left: 1px solid;
}
.grid .grid-first-row {
  background-color: #008080;
  color:#ddd;
  font-weight: bold;

  text-transform: lowercase;
}
.grid.is-first {
  border-top: 5px solid ;
}
.grid.is-header .grid-item{
  box-sizing: border-box;
  font-weight: bold;
  font-size:12px;
  text-align: center;
  padding: 10px;
  border-right: 1px solid #938383;
  color:#fff;
}
.odd {
  background-color: #F8F6F6;
}
.even {
  background-color: white;
}
.grid .grid-hover:hover{
  background: #eee;
}
.grid:hover {
  background-color: #ddd;
}
.catalogItems {
  margin-bottom: 15px;
}
.grid .button {
  font-weight: bold;
  margin:3px;
  min-width: 80px;
}
.grid-item:hover {
 font-weight: bold;
 background-color: #FFF;
}
.grid .grid-item {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  box-sizing: border-box;
  cursor: pointer;
  border-right: 1px solid ;
  border-bottom:1px solid;
  padding:5px;
  font-size: 16px;
  width: 100%;
  overflow: hidden;
  min-height: 28px;;
  max-height: 80px;
}
.catalog-section {
  width:100%;
  background-color: white;
  padding:0px 25px;
}
.section {
  padding: 0px 12%;
}
.modal-section {
  width:100%;
  padding:30px 50px;
}
.title.is-vertical-one,.title.is-1 {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  position:absolute;
  text-transform: uppercase;
  left:0;
  font-size:80px;
  color:#ddd;
  z-index: -1000;
}
.app {
  height: 100vh;
  width:100vw;
}
.is-group-wrap {
    border-radius: 5px;
    border:1px solid #008080;
    margin-bottom:50px;
    padding:0px;
}
.field-group {
  background:#eee;
  padding:10px;
  margin:25px 0px;
  border-radius: 5px;
  text-align: left;
}
.divider {
  border-bottom:10px solid #ddd;
  margin-bottom:30px;
  padding-left:40px;
  padding-bottom: 30px;
}
.divider-items {
  border-bottom:1px solid #ddd;
  margin-bottom:30px;
  padding-left:40px;
  padding-bottom: 20px;
}
.is-field {
  text-transform: capitalize;
  font-weight: bold;
}
.text-bold {
  font-weight: bold;
}
.buttons .button {margin-right:10px;}
.borderLeft {
  border-left:1px solid #ddd;
}

.columns.no-padding .column {
  padding:1px 3px;
  margin:0;
}
.divider-bottom {
  border-bottom:1px dashed #666;
  margin-bottom:30px;
  padding-bottom: 30px;
}
.group {
  font-size:14px;
  text-transform: uppercase;
  font-weight: normal;
  color:#555;
}
.loader-wrap {

  height: 100vh;
  width:100vw;
}
.loader-box {
  display:block;
  width:80px;
  overflow: hidden;;
  margin:0 auto;
  text-align: center;
  margin-top:60px;
}
.loader {
  border: 16px solid orange; /* Light grey */
  border-top: 16px solid #60696e; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}
.is-content {
  position: relative;
  padding-top:0px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.is-item{
  font-size:1.1em;
  font-weight: normal;
  text-align: left;
  color:#008080;
  padding:0px;
  min-height:180px;
}
.fa {color:#000}
.is-right {
  float:right;
}
.is-center {
  text-align: center;
}
.navbar {
  background:#000;

}
.is-info {
  margin-top:0;
  padding-bottom:40px;
  width:100%;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size:14px;
  font-style: italic;
}
.navbar * {
  color:#eee;
  text-transform: uppercase;
}
a:hover {
  opacity: .5;
  transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -webkit-transition: opacity .5s ease-in-out;
}
.has-border {
  border:2px solid #ddd;
}
.has-border-bottom {
  border-bottom:1px dotted #ddd;
}
.childs-to-left .child{
  width:50%;
  padding:0;
  margin:0px 0px 10px;
  float:left;
}
.column img {max-width:100%;}
.is-white {background: #fff}
.column{
  word-break:break-all;
}
.columns.is-header {
  background:#eee;
  font-weight: bold;
  text-transform: uppercase
}
.is-white {
  background: #fff;
}
.modal-content {
  width:80%;
  border-radius:15px;
}
.r-10 {
  margin-right:10px;
}
.r-20 {
  margin-right:20px;
}
.login-box {
  border-radius:10px;
  padding:25px;
}
/* color */
.is-disabled .columns{
  background: #ddd;
  display: none;
}
.is-text-green {
  color:green;
}
.is-text-red {
  color:red;

}
.app-logo {
  max-height:80px;
  border:2px dotted #ddd;
  background-color: #60696e;
}

.main-columns .title, .main-columns .subtitle {
  margin-left:40px;
}
.back-button {
  position: fixed;
  display: block;
  top:-20px;
  left:20px;
  z-index:16;
  width:30px;
  padding:30px 0px 0px 10px;
  text-align: left;
  color:#fff;
}
.back-button:hover i{
  opacity: .5;
}
.back-button i {
  color:#fff;
  font-size:30px;
  margin:0px;
  font-weight: normal;
}
.is-button-plus {
  position: absolute;
  bottom:30px;
  right:30px;
  box-shadow:1px 1px 2px #635D5D;
  background: #F8F6F6;
  border:solid 1px #ddd;
  display: block;
  float:left;
  padding:20px 25px;
  border-radius: 100%;
}
.is-button-plus i {
  font-size:40px;
  color:#635D5D;
  font-weight: normal;
  cursor: pointer
}
.progress-bar .sr {
  background: green
}
.column img.max-w-140 {
  width:240px;
}
.full-width {
  width:100%;
}
.modal.bring-front {
  z-index:200;
  background:#000;
  height:100vh;
  width:100vw;
}
/* aside menu */
.main {
  z-index:50;
  width:100%;
  height: 100vh;
  display:flex;
  padding-top:3.5rem;
}

.header {
   width:100vw;
   height:40px;
   position: absolute;
}

.menu-wrap {
  height:50px;
  width:100%;
  position: fixed;
  overflow: hidden;
  left:0;
  z-index: 15;
  top:0;
 
}
/* ALL  COLOR */
.menu-wrap{background:rgb(80, 78, 78)}
.grid, .grid.is-first, .grid .grid-item{ border-color:rgb(80, 78, 78)}
.grid.is-header .grid-item {background-color: rgb(80, 78, 78)}
.icon-pages, .icon-pages:hover{color:rgb(80, 78, 78);}
.image-wrap {border-color: rgb(80, 78, 78);}

.icon-pages i, .icon-pages span{color:rgb(80, 78, 78)}
/* END COLOR DFINED */
.menu {
  height: 50vh;
  width:400px;
  margin:0 auto;
  display: flex;
}
.menu li {
  width:100%;
  padding-top:5px;
}

.menu li a {
  color:#fff;
  font-weight: normal;;
  text-transform: uppercase;
  font-size: .9em;
  padding:0px 0px;
  font-family: 'Saira Extra Condensed', sans-serif;
  margin:0;
  opacity: .8;
}
.menu li a:hover {
  background: transparent;
  opacity: 1;
}
.menu .button {
  min-width: 80%;
  margin-left:0%;
  border-radius: 10px;
  padding:5px;
}

.formfield {
  margin-bottom:20px;
}
.pub-btn {
  position: absolute;
  right:25px;
  top:5px;
  color:#fff;
  font-weight: bold;
  text-transform: uppercase;
  z-index:inherit;
  
}
.input, .field .button {
  font-size:16px;
}

.pub-btn .button {
  font-size:18px;
  font-weight: normal;
  letter-spacing: 1px;
  background-color: green;
}
body.modal-open {
  overflow: hidden;
  position: fixed;
}
.media {
  border:20px solid #ddd;
 
  overflow:scroll;
}
.image-wrap{
  border:4px solid;
  cursor: pointer;
  overflow: hidden;
  padding:0;
  margin-bottom: 20px;
  width:50%;
  max-height: 250px;;
}
.image-wrap img {
  margin:0;
  width:120%;
  padding:0;
  float:left;
}
.media-image .image-wrap:hover {
  opacity: .5;
  
}
.pub-btn i {
  color:#fff;
}
.menu .button * {
  color:#eee;
  font-weight: bold;
}
.section {
  padding-top:20px;
  width:100%;
  float:left;
}
/* this will fix a padding bug */
.section .columns {
  margin:0;
}
.logo img {
  max-width: 80%;
  margin-left:10%;
}
.icon-pages {
  cursor: pointer;
  text-align: left;
  display: block;
  padding:20px;
  margin:20px 2.5%;
  width:20%;
  border:5px solid;
  padding:30px 15px;
  
  float:left;
}
.icon-pages i {
  display:block;
  font-size:75px;
  text-align: center;
 
}
.icon-pages span {
  display:block;
  font-size:1.4em;
  font-weight: bold;
  margin-top:5px;
  text-align: center;
  font-family: 'Saira Extra Condensed', sans-serif;
}

.small-text {
  font-size:13px;
  font-weight: bold;
}
.modal {
  height: 100vh;
  width:100vw;
}
.login-wrap {
  width:50vw;
  margin-left:25vw;
  margin-top:30px;
  border:2px solid #ddd;
  padding:5vw;
  border-radius:10px;
}

@media only screen and (max-width: 800px) {
  .icon-pages {
    width:100%;
  }
}
.catalogItems .button {
  margin-right: 8px;
}
a.is-selected {
  background-color: #eee;
  border-color: #000;
}
@import 'Editor.css'
