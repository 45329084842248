.hamburger {
    cursor: pointer;
    position: absolute;
    right:10px;
    height:3.2rem;
    width:2.9rem;
    top:0;
}
.hamburger span{
    display: block;
    position: absolute;
    width:80%;
    height:5px;
    background: #fff;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.hamburger span:before,
.hamburger span:after {
    content:"";
    display: block;
    position: absolute;
    width:100%;
    height:5px;
    background: #fff;
    transition: .3s;
}

.hamburger span:before{
    top:-10px;
}
.hamburger span:after {
   top:10px;
}

.hamburger.is-active span:before{
    width:100%;
    top:0px;
    transform: rotate(45deg);
}
.hamburger.is-active span:after{
    top:0px;
    width:100%;
    transform: rotate(-45deg);
}
.hamburger.is-active span{
    background: transparent;
    transition: .2s;
 }
 @media screen and (max-width: 1023px) {
     .hamburger {
         display: block;
     }
 }
